







































import { Vue, Component, Prop, PropSync, Ref } from 'vue-property-decorator'
import { Loja } from '@/models'
import ConfirmExclusionDialog from '@/components/ui/ConfirmExclusionDialog.vue'
import StatusDaComanda from './StatusDaComanda.vue'
import { Comanda } from '@/models/Comanda'
import DialogoDeComandas from '@/views/application/comandas/DialogoDeComandas.vue'
import { dateTimeToPtBrFormat } from '@/shareds/date/date-utils'
import { ComandaUseCase } from '@/usecases'
import AlertModule from '@/store/vuex/aplicacao/AlertModule'
import { imprimirCupomDaComanda } from '@/shareds/pedidos/impressao-do-cupom-da-comanda'
import Confirmacao from '../ui/Confirmacao.vue'
import { formatarMoeda } from '@/shareds/formatadores'

@Component({
	components: {
		ConfirmExclusionDialog,
		DialogoDeComandas,
		StatusDaComanda,
		Confirmacao,
	},
})
export default class CardDaComandas extends Vue {
	@PropSync('value') comanda!: Comanda
	@Prop({ type: Boolean, default: false }) esconderAcoes!: boolean
	@PropSync('loja', { type: Object }) lojaSync?: Loja
	@Ref() confirmacao!: Confirmacao

	comandaUpdate: Comanda | null = null

	dateTimeToPtBrFormat = dateTimeToPtBrFormat
	formatarMoeda = formatarMoeda

	comandaUseCase = new ComandaUseCase

	async salvar(comanda: Comanda) {
		this.comandaUpdate = comanda
		try {
			if(comanda.itemDaComanda.length > 0 && comanda.aberto) await this.confirmacao.mostrar()

			await this.comandaUseCase.save(comanda)
		} catch(error) {
			AlertModule.setError(error)
		} finally {
			AlertModule.setSuccess('Comanda salva com sucesso')
			
			this.$emit('input', comanda)
		}
	}

	async imprimirCupomDaComanda() {
		if(!this.comandaUpdate) return
		try {
			await imprimirCupomDaComanda(this.comandaUpdate)
		} catch(error) {
			AlertModule.setError(error)
		} finally {
			this.comandaUpdate = null
		}
	} 
}

