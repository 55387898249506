















































































import { Categoria, FiltroDeBuscaDeProdutos, FormProdutoSimples, FormProdutoSimplesFilho, ItemDaComanda, Pageable, ProdutoComVariantes, ProdutoFilho, TipoDeProduto } from '@/models';
import { converterProdutoParaProdutoSimples } from '@/shareds/produto-shareds';
import AlertModule from '@/store/vuex/aplicacao/AlertModule';
import { FindCategoriaUseCase, FindProdutoUseCase } from '@/usecases';
import Axios from 'axios';
import axios, { CancelTokenSource } from 'axios';
import { Component, Prop, Ref, Vue, Watch } from 'vue-property-decorator';
import AppLoading from '../ui/AppLoading.vue';
import CampoDeItemDeVenda from '../venda/CampoDeItemDeVenda.vue';
import CardDeProduto from './CardDeProduto.vue';
import DialogoDeSelecionarVariante from './DialogoDeSelecionarVariante.vue';


@Component({
	components: {
		DialogoDeSelecionarVariante,
		CardDeProduto,
		AppLoading,
	},
})
export default class SeletorDeProdutos extends Vue {
	@Ref() readonly selecaoDeVariante!: DialogoDeSelecionarVariante
	@Ref() readonly campoDeBusca!: CampoDeItemDeVenda
	@Prop({ type: Boolean, default: false }) mostrar!: boolean
	@Prop() lojaId!: string | null

	selecionandoProduto = false
	carregandoProdutos = false

	categorias: Categoria[] = []
	cancelToken: CancelTokenSource | null = null
	produtos: FormProdutoSimples[] = []
	busca = ''
	filtroAplicado: FiltroDeBuscaDeProdutos = {
		tipos: ['Padrão', 'Com Variantes', 'Composto'],
	}

	findProdutoUseCase = new FindProdutoUseCase()
	findCategoriaUseCase = new FindCategoriaUseCase()

	cancelar() {
		this.mostrar = false
		this.busca = ''
		this.categorias = []
		this.$emit('cancelar', this.mostrar)
	}

	@Watch('busca', { deep: true })
	async onChangeBusca(busca: string) {
		this.filtroAplicado.identificador = busca
		this.buscarCategorias()
		this.buscarProdutos()
	}

	@Watch('mostrar', { deep: true })
	async onChangeMostrar(busca: string) {
		if(this.mostrar) {
			this.filtroAplicado.identificador = busca
			this.buscarCategorias()
		} else {
			this.filtroAplicado.identificador = ''
			this.busca = ''
			this.filtroAplicado.marcas = []
		}
		
	}

	async selecionarProduto(produtoSimplificado: FormProdutoSimples) {
		try {
			if(!this.lojaId) throw new Error('Nenhuma loja selecionada') 
			this.selecionandoProduto = true
			if (produtoSimplificado.tipo === 'Com Variantes') {
				const produto = this.findProdutoUseCase.findProdutoById(produtoSimplificado.id)
				this.selecaoDeVariante.abrir(produto as Promise<ProdutoComVariantes>)
				return
			}

			const produto = await this.findProdutoUseCase.findProdutoById(produtoSimplificado.id)
			if (produto.tipo !== 'Com Variantes')
				this.incluirProduto(produto)
		} catch(error) {
			AlertModule.setError(error)
		} finally {
			this.selecionandoProduto = false
			this.produtos = []
		}
	}

	async selecionarCategoria(categoria: Categoria, active: boolean) {
		this.filtroAplicado.categorias = active ? [ categoria.id ] : undefined
		this.buscarProdutos()
	}

	async buscarCategorias() {		
		const page = await this.findCategoriaUseCase.findAll({ size: -1 }, {})
		this.categorias = page.content
	}

	async buscarProdutos(paginavel: Pageable = {page: 0, size: 40}) {
		this.carregandoProdutos = true

		if (this.cancelToken) this.cancelToken.cancel()
		this.cancelToken = axios.CancelToken.source()

		const axiosConfig = {
			cancelToken: this.cancelToken.token,
		}

		const params = {
			...this.filtroAplicado,
			tipos: ['Padrão', 'Com Variantes', 'Composto'] as TipoDeProduto[],
			lojaId: this.lojaId,
			identificador: this.filtroAplicado.identificador && this.filtroAplicado.identificador?.length > 0 ? this.filtroAplicado.identificador : null,
			...paginavel,
		}

		try {
			const produtosPage = await this.findProdutoUseCase.findProdutosSeletor(params, axiosConfig)
			this.produtos = produtosPage.content
			this.carregandoProdutos = false
		}
		catch(error) {
			if (Axios.isCancel(error))
				this.carregandoProdutos = false
		}
	}

	

	incluirProduto(produto: ProdutoFilho | FormProdutoSimplesFilho) {
		try {
			const itemDaComanda: ItemDaComanda = {
				id: '',
				produto: converterProdutoParaProdutoSimples(produto),
				desconto: { isPercentual: true, valor: 0 },
				preco: produto.preco || 0,
				quantidade: 1,
				precoDeCusto: 0,
				idConjunto: null,
				observacao: '',
			};
			this.$emit('inserirProduto', itemDaComanda)
			this.focarBusca()
		} catch (error) {
			AlertModule.setError(error)
		}
	}

	focarBusca() {
		if(this.$route.query.voltarPraPedido === 'true') return
		setTimeout(() => {
			this.campoDeBusca.focus()
		})
	}

}
